.title-login {
  font-family: 'Helvethaica_Bold';
  font-size: 26px;
}

.form-input {
  font-size: 20px;
}

.btn-submit {
  font-size: 20px;
  font-family: 'Helvethaica_Medium';
}

.login {
  max-height: '45px';
  margin-bottom: 7%;
}
.border-footer {
  border-top: 1px solid #28825A;
}
.btn-fg-regis {
  margin-top: 10%;
}
.mw-login {
  max-height: 472px;
  max-width: 660px;
}
.bg-style {
  background-image: linear-gradient(to bottom, #bdddcb 99.4%, #32AA78 5%);
  background-repeat: no-repeat;
  background-size: 100% 76%;
  background-position: top center;
  overflow: hidden;
  height: 100%;
}
.login-button {
  margin-top: 0%;
}
@media only screen and (min-width: 600px) {
  .login {
    margin-bottom: 12%;
  }
  .login-button {
    margin-top: 1.5%;
  }
  .bg-style {
    background-size: 100% 77%;
  }
}

@media only screen and (min-width: 768px) {
  .login {
    font-size: clamp(1px, 1.5vw, 16px);
    margin-bottom: 3%;
  }

  .border-footer {
    border: none;
  }
  .on-bottom {
    position: fixed;
    bottom: 0;
  }
  .bg-style {
    background-size: 100% 48%;
  }
}
@media only screen and (min-width: 992px) {
  .login {
    margin-bottom: 5.9%;
  }
  .bg-style {
    background-size: 100% 54%;
  }
}

@media only screen and (min-width: 1200px) {
  .login {
    margin-bottom: 7%;
  }
  .bg-style {
    background-size: 100% 62%;
  }
}
@media only screen and (min-width: 1400px) {
  .login {
    margin-bottom: 8.5%;
  }
  .bg-style {
    background-size: 100% 62%;
  }
}
@media only screen and (min-width: 1800px) {
  .login {
    margin-bottom: 11.5%;
  }
  .bg-style {
    background-size: 100% 63%;
  }
}
