@font-face {
  font-family: 'Helvethaica_Regular';
  src: url('./assets/fonts/DB_Helvethaica_X/DBHelvethaicaX-55Regular.woff2') format('woff2'), url('./assets/fonts/DB_Helvethaica_X/DBHelvethaicaX-55Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvethaica_Medium';
  src: url('./assets/fonts/DB_Helvethaica_X/DBHelvethaicaX-65Med.woff2') format('woff2'), url('./assets/fonts/DB_Helvethaica_X/DBHelvethaicaX-65Med.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvethaica_Bold';
  src: url('./assets/fonts/DB_Helvethaica_X/DBHelvethaicaX-75Bd.woff2') format('woff2'), url('./assets/fonts/DB_Helvethaica_X/DBHelvethaicaX-75Bd.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body,
html {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Noto Sans Thai', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: 'Helvethaica_Regular'; */
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a:-webkit-any-link {
  text-decoration: none;
  /* color: rgb(113, 2, 18); */
  color: #28825A;
}

.swal2-html-container {
  font-size: 24px !important;
}

.swal2-confirm {
  font-size: 20px !important;
}

.fix {
  position: fixed;
  bottom: 0px;
  left: 50%;
}

.text-pri {
  /* color: #710212 !important; */
  color: #28825A !important;
}
.text-sec {
  /* color: #c85060 !important; */
  color: #32AA78 !important;
}
.text-tri {
  /* color: #f6c4ca !important; */
  color: #cee3d7 !important;
}
.text-pink {
  /* color: #f185b7 !important; */
  color: #3CBE8C !important;
}
.bg-pri {
  /* background-color: #710212 !important; */
  background-color: #28825A !important;
}
.bg-sec {
  /* background-color: #c85060 !important; */
  background-color: #32AA78 !important;
}
.bg-tri {
  /* background-color: #f6c4ca !important; */
  background-color: #cee3d7 !important;
}
.bg-four {
  /* background-color: #f2adb6 !important; */
  background-color: #bdddcb !important;
}
.bg-pink {
  /* background-color: #f185b7 !important; */
  background-color: #3CBE8C !important;
}
.bg-skypink {
  background-color: #fac4dd !important;
  background-color: #92c1a7 !important;
}
.bg-grey {
  background-color: #d1d3d4 !important;
}
.border-pri {
  /* border-color: #710212 !important; */
  border-color: #28825A !important;
}
.border-sec {
  /* border-color: #c85060 !important; */
  border-color: #32AA78 !important;
}
.border-tri {
  /* border-color: #f6c4ca !important; */
  border-color: #cee3d7 !important;
}
.border-pink {
  /* border-color: #f185b7 !important; */
  border-color: #3CBE8C !important;
}
.border-image {
  border: 8px solid transparent;
  border-radius: 15px;
}
#monitorLeg {
  border-radius: 0px 0px 50px 50px;
  width: 100%;
  height: 8px;
  margin: auto;
}
/* .nav-link {
  transition: all 0.2s;
  border-bottom: 2px solid rgb(200, 80, 96);
}
.nav-link:hover {
  color: inherit;
  border-bottom: 2px solid red;
}

.nav-link::after {
  content: '';
  opacity: 0;
  transition: all 0.2s;
  height: 2px;
  width: 100%;
  border-bottom: 2px solid red;
  position: absolute;
  bottom: 0;
  left: 0;
} */

.nav-link:hover::after {
  opacity: 1;
}

.strike {
  display: block;
  text-align: center;
  overflow: hidden;
}
.strike > div {
  position: relative;
  display: inline-block;
}

.strike > div:before,
.strike > div:after {
  content: '';
  position: absolute;
  top: 50%;
  width: 200%;
  height: 2px;
  background: transparent;
}

.strike > div:before {
  right: 100%;
  margin-right: 15px;
}

.strike > div:after {
  left: 100%;
  margin-left: 15px;
}

.strike > span {
  position: relative;
  display: inline-block;
}

.strike > span:before,
.strike > span:after {
  content: '';
  position: absolute;
  top: 50%;
  width: 200%;
  height: 2px;
  background: transparent;
}

.strike > span:before {
  right: 100%;
  margin-right: 15px;
}

.strike > span:after {
  left: 100%;
  margin-left: 15px;
}

.img-semicircle {
  border-radius: 50% 50% 24% 24% / 55% 55% 45% 45%;
}

.tree-login {
  position: absolute;
}
footer {
  position: relative;
  left: 10em;
  bottom: 0;
  right: 0;
}
body {
  overflow-y: auto;
}

.thumbnail {
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
}
.thumbnail img {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: auto;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.thumbnail img.portrait {
  width: 100%;
  height: auto;
}

ul {
  list-style: none; /* Remove default bullets */
}

/* ul li::before {
  content: '•';
  color: #c7505f;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
} */

.bg-welcome {
  /* background-color: #f2adb6; */
  background-color: #bdddcb;
}

.tree {
  background-image: url('assets/images/CircleTree.png');
  background-repeat: no-repeat;
  width: 196.8624px;
  height: 150.822px;
  background-position: center bottom;
  background-size: 100%;
  overflow: hidden;
}
.tree-nav {
  background-image: url('assets/images/CircleTree.png');
  background-repeat: no-repeat;
  width: 150px;
  height: 100px;
  background-position: center;
  overflow: hidden;
  background-size: 155px 111px;
  margin-bottom: -6%;
}

.frame-nav {
  overflow: hidden;
  display: inline-block;
  position: relative;
  width: 83px;
  height: 83px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin-left: 36px;
  margin-top: 28px;
}

.frame-setting {
  overflow: hidden;
  display: inline-block;
  position: relative;
  height: 107px;
  width: 107px;
  /* width: 55%; */
  /* height: 150px; */
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin-top: 27%;
  margin-left: 24.4%;
  /* margin-bottom: -8%; */
}

.clipped-image {
  object-fit: cover;
  height: 100%;
  width: auto;
  /* margin-left: -5%; */
}
input[type='file']::file-selector-button {
  border-radius: 0.1em;
  /* background-color: #f79fc7; */
  background-color: #3CBE8C;
  transition: 0.5s;
  color: white;
}
input::placeholder {
  color: #32AA78 !important;
}

.w-my {
  width: 100% !important;
}

.btn-footer {
  width: 150px;
  margin-top: -73px;
}

.btn-del {
  width: 160px;
  margin-top: -50px;
}
.bth-setting {
  width: 150px;
}
.frame {
  overflow: hidden;
  display: inline-block;
  position: relative;
  width: 55%;
  /* height: 150px; */
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin-top: 30%;
  margin-left: 24.5%;
  margin-bottom: -7%;
}
.name {
  font-size: 12px;
}
.user-post {
  font-size: 8px;
}
.logo-full {
  width: 200px;
}
.img-menu-nav {
  max-height: 33px !important;
}

@media only screen and (max-width: 500px) {
  .frame {
    margin-bottom: -10%;
  }
}
@media only screen and (min-width: 500px) {
  .img-menu-nav {
    max-height: 50px !important;
  }
  .tree {
    width: 310px;
    height: 237.5px;
  }
  .frame-setting {
    height: 170px;
    width: 170px;
    /* margin-left: 24.3%;
    margin-top: 26.5%; */
  }
  .name {
    font-size: 18px;
  }
  .user-post {
    font-size: 14px;
  }
}
.w-dash-logo {
  width: 50%;
}

@media only screen and (min-width: 768px) {
  .img-menu-nav {
    max-height: 55px !important;
  }
  .bg-welcome {
    background-image: url('assets/images/bgWelcome1.png');
    background-color: #bdddcb;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center bottom;
    overflow: hidden;
    position: relative;
  }

  .logo-full {
    width: 500px;
  }
  .bth-setting {
    width: 200px;
  }
  .tree {
    width: 620px;
    height: 475px;
  }
  .frame-setting {
    height: 170px;
    width: 170px;
    margin-left: 24.3%;
    margin-top: 26.5%;
  }
  .name {
    font-size: 20px;
  }
  .user-post {
    font-size: 18px;
  }
  .strike > span:before,
  .strike > span:after {
    background: rgb(255, 255, 255);
  }
  .strike > div:before,
  .strike > div:after {
    background: #32AA78;
  }
  .btn-footer {
    width: 270px;
    margin-top: -130px;
  }
  .border-image {
    border: 10px solid transparent;
    border-radius: 15px;
  }
  #monitorLeg {
    height: 15px;
  }
  .w-dash-logo {
    width: 75%;
  }
  .btn-del {
    width: 250px;
    margin-top: -77px;
  }

  .btn-w {
    width: 300px;
    margin-top: -150px;
  }

  .w-my {
    width: 75% !important;
  }

  .tree {
    width: 310px;
    height: 237.5px;
  }
  .frame {
    margin-left: 24.2%;
    margin-top: 26.5%;
  }
}
@media only screen and (min-width: 992px) {
  .tree {
    width: 620px;
    height: 475px;
  }
  .frame-setting {
    height: 340px;
    width: 340px;
    margin-left: 24.3%;
    margin-top: 26.5%;
  }
}
@media only screen and (min-width: 1200px) {
}
@media only screen and (min-width: 1400px) {
}
.font-name {
  font-size: clamp(1px, 3.6vw, 20px);
  overflow: hidden;
  text-overflow: ellipsis;
}
.font-post {
  font-size: clamp(1px, 3vw, 20px);
  overflow: hidden;
  text-overflow: ellipsis;
}
.font-locate-pri-nav {
  font-size: clamp(1px, 5vw, 22px);
}
.font-locate-sec-nav {
  font-size: clamp(1px, 5vw, 22px);
}
.font-pri-nav {
  font-size: clamp(1px, 3vw, 22px);
}
.font-sec-nav {
  font-size: clamp(1px, 1vw, 14px);
}
.font-p {
  font-size: clamp(1px, 5vw, 14px);
}
.font-h5 {
  font-size: clamp(1px, 5vw, 14px);
}
.font-h4 {
  font-size: clamp(1px, 5vw, 16px);
}
.font-h3 {
  font-size: clamp(1px, 5vw, 20px);
}

.font-h2 {
  font-size: clamp(1px, 5vw, 24px);
}

.font-h1 {
  font-size: clamp(1px, 5vw, 30px);
}
